import {z} from 'zod';

import {useTranslations} from '@/hooks/useTranslations';
import {BaseInput} from '@/components/shared/validatedForm/legacy/BaseInput';
import {type BaseInputProps} from '@/components/shared/BaseInput/BaseInput';

type WebsiteProps = {
  inputProps?: Omit<BaseInputProps | 'id', 'type'>;
};

export const websiteValidator = {
  website: z.string().min(1, {message: 'forms:lead.website.error'}),
};

export default function EmailTextInput({inputProps}: WebsiteProps) {
  const {t} = useTranslations();
  return (
    <BaseInput
      placeholder={t('forms:lead.website.label')}
      label={t('forms:lead.website.label')}
      {...inputProps}
      id="website"
      type="url"
    />
  );
}
